<template>
    <div id="pageTop">
        <!-- ====================================
        --- PRELOADER
        ===================================== -->
        <div id="preloader" class="smooth-loader-wrapper">
            <div class="smooth-loader">
                <div class="loader">
                    <span class="dot dot-1"></span>
                    <span class="dot dot-2"></span>
                    <span class="dot dot-3"></span>
                    <span class="dot dot-4"></span>
                </div>
            </div>
        </div>

        <!-- ====================================
        --- HEADER
        ===================================== -->
        <header>
            <!-- Top Color Bar -->
            <div class="color-bars">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col color-bar bg-warning d-none d-md-block"></div>
                        <div class="col color-bar bg-success d-none d-md-block"></div>
                        <div class="col color-bar bg-danger d-none d-md-block"></div>
                        <div class="col color-bar bg-info d-none d-md-block"></div>
                        <div class="col color-bar bg-purple d-none d-md-block"></div>
                        <div class="col color-bar bg-pink d-none d-md-block"></div>
                        <div class="col color-bar bg-warning"></div>
                        <div class="col color-bar bg-success"></div>
                        <div class="col color-bar bg-danger"></div>
                        <div class="col color-bar bg-info"></div>
                        <div class="col color-bar bg-purple"></div>
                        <div class="col color-bar bg-pink"></div>
                    </div>
                </div>
            </div>

            <cookie-law theme="royal">
                <div class="banner" slot-scope="props">
                    <span>
                        Ce site n'utilise pas de cookie et ne stocke aucune donnée personnelle.
                    </span>
                    <button class="btn btn-success bannerBtn" @click="props.accept"><span>J'ai compris!</span></button>
                </div>
            </cookie-law>

            <!-- Navbar -->
            <nav class="navbar navbar-expand-md navbar-scrollUp navbar-white" id="menu">
                <div class="container">
                    <div>
                        <a class="navbar-brand" href="#slbp" @click="showContent('#school')">
                            <img class="d-inline-block img-fluid" src="../assets/img/logo-St-Louis-Blaise-Pascal.jpg" alt="Ecole SLBP">                       
                        </a>
                    </div>
                    <button class="navbar-toggler py-2" type="button" data-toggle="collapse" data-target="#navbarContent"
                            aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i class="fa fa-bars"></i>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarContent">
                        <ul class="navbar-nav text-left">
                            <li class="nav-item dropdown bg-warning">
                                <a class="nav-link dropdown-toggle" href="#school" @click="showContent('#school')">
                                    <i class="fa fa-home nav-icon" aria-hidden="true"></i>
                                    <span>L'école</span>
                                </a>
                            </li>

                            <li class="nav-item dropdown bg-danger">
                                <a class="nav-link dropdown-toggle " href="#subscription" @click="showContent('#subscription')">
                                    <i class="fa fa-pencil-square-o nav-icon" aria-hidden="true"></i>
                                    <span>Inscription</span>
                                </a>
                            </li>

                            <li class="nav-item dropdown bg-success">
                                <a class="nav-link dropdown-toggle " href="#apel" @click="showContent('#assos')">
                                    <i class="fa fa-user nav-icon" aria-hidden="true"></i>
                                    <span>APEL - OGEC</span>
                                </a>
                            </li>

                            <li class="nav-item dropdown bg-purple">
                                <a class="nav-link dropdown-toggle " href="#calendrier" @click="showContent('#calendrier')">
                                    <i class="fa fa-calendar nav-icon" aria-hidden="true"></i>
                                    <span>Calendrier</span>
                                </a>
                            </li>

                            <li class="nav-item dropdown bg-warning">
                                <a class="nav-link dropdown-toggle " href="#classes" @click="showContent('#classes')">
                                    <i class="fa fa-chair nav-icon" aria-hidden="true"></i>
                                    <span>Info classes</span>
                                </a>
                            </li>

                            <li class="nav-item dropdown bg-info">
                                <a class="nav-link dropdown-toggle " target="_blank" href="https://app.educartable.com">
                                    <i class="fa fa-suitcase nav-icon" aria-hidden="true"></i>
                                    <span>Edu-cartable</span>
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </header>

        <div class="main-wrapper home">
            <div v-if="messageAlerte" class="messagedefilant alert">
                <div v-bind:data-text="fullMsgAlerte"></div>
            </div>

            <!--====================================
            --- BEGIN MAIN SLIDE LIST
            ===================================== -->
            <section class="bg-parallax-custom" :style="{ 'background-image': 'url(' + require('../assets/img/ecole-St-Louis-Blaise-Pascal.jpg') + ')' }">
            
            </section>

            <!-- ====================================
            ---	BOX SECTION
            ===================================== -->
            <section class="d-none d-sm-block section-top">
                <div class="container">
                    <div class="row wow fadeInUp">
                        <div class="col-sm-3">
                            <a href="#lastNews">
                                <div class="card bg-warning card-hover scrolling">
                                    <div class="card-body text-center p-0">
                                        <div class="card-icon-border-large border-warning">
                                            <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                        </div>
                                        <h2 class="text-white font-size-32 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">Actus</h2>
                                        <a href="#lastNews" class="btn-scroll-down d-block pb-4 pb-lg-5">
                                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div class="col-sm-3">
                            <a href="#menuCantine">
                                <div class="card bg-success card-hover scrolling">
                                    <div class="card-body text-center p-0">
                                        <div class="card-icon-border-large border-success">
                                            <i class="fa fa-cutlery" aria-hidden="true"></i>
                                        </div>
                                        <h2 class="text-white font-size-32 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">Cantine</h2>
                                        <a href="#menuCantine" class="btn-scroll-down d-block pb-4 pb-lg-5">
                                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div class="col-sm-3">
                            <a href="#APELNews">
                                <div class="card bg-danger card-hover scrolling">
                                    <div class="card-body text-center p-0">
                                        <div class="card-icon-border-large border-danger">
                                            <i class="fa fa-users" aria-hidden="true"></i>
                                        </div>
                                        <h2 class="text-white font-size-32 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">APEL</h2>
                                        <a href="#APELNews" class="btn-scroll-down d-block pb-4 pb-lg-5">
                                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div class="col-sm-3">
                            <a href="#gallery-title">
                                <div class="card bg-info card-hover scrolling">
                                    <div class="card-body text-center p-0">
                                        <div class="card-icon-border-large border-info">
                                            <i class="fa fa-picture-o" aria-hidden="true"></i>
                                        </div>
                                        <h2 class="text-white font-size-32 pt-1 pt-lg-5 pb-2 pb-lg-6 mb-0 font-dosis">Galerie</h2>
                                        <a href="#gallery-title" class="btn-scroll-down d-block pb-4 pb-lg-5">
                                            <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section class="mini mini-none section-top">
                <div class="miniContent">
                    <div>
                        <a href="#lastNews">
                            <div class="newsCircle bg-warning scrolling" >                                    
                                <h4 class="text-white py-6 font-size-20">Actus</h4>
                                <!-- <div class="miniChevron newsChevron btn-scroll-down d-block">
                                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                </div>                                             -->
                            </div>
                        </a>
                    </div>
                    <div>
                        <a href="#menuCantine">
                            <div class="cantineCircle bg-success scrolling" >                                    
                                <h4 class="text-white py-6 font-size-20">Cantine</h4>
                                <!-- <a href="#lastNews" class="btn-scroll-down d-block pb-4 pb-lg-5">
                                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                </a>                                             -->
                            </div>
                        </a>
                    </div>
                    <div>
                        <a href="#APELNews">
                            <div class="apelCircle bg-danger scrolling" >                                    
                                <h4 class="text-white py-6 font-size-20">APEL</h4>
                                <!-- <a href="#lastNews" class="btn-scroll-down d-block pb-4 pb-lg-5">
                                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                </a>                                             -->
                            </div>
                        </a>
                    </div>
                    <div>
                        <a href="#gallery-title">
                            <div class="galleryCircle bg-info scrolling" >                                    
                                <h4 class="text-white py-6 font-size-20">Galerie</h4>
                                <!-- <a href="#lastNews" class="btn-scroll-down d-block pb-4 pb-lg-5">
                                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                </a>                                             -->
                            </div>
                        </a>
                    </div>
                </div>                                                
            </section>

            <!-- ====================================
            ---	HOME FEATURE
            ===================================== -->
            <section class="pt-9 pb-6 py-md-7">
                <div class="container">
                    <div class="section-title justify-content-center mb-4 mb-md-8 wow fadeInUp">
                        <span class="shape shape-left bg-info"></span>
                        <h2 class="text-danger">Nos points forts</h2>
                        <span class="shape shape-right bg-info"></span>
                    </div>

                    <div class="row wow fadeInUp">
                        <!-- Media -->
                        <div class="col-sm-6 col-xl-4 col-xs-12">
                            <div class="media mb-6">
                                <div class="media-icon-large bg-warning mr-xl-4">
                                    <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                                </div>

                                <div class="media-body">
                                    <h3 class="text-warning">Enseignement Adapté</h3>
                                    <p>Diversité culturelle et usage de techniques éprouvées comme la Méthode Singapour.</p>
                                </div>
                            </div>
                        </div>

                        <!-- Media -->
                        <div class="col-sm-6 col-xl-4 col-xs-12">
                            <div class="media mb-6">
                                <div class="media-icon-large bg-success mr-xl-4">
                                    <i class="fa fa-leaf" aria-hidden="true"></i>
                                </div>

                                <div class="media-body">
                                    <h3 class="text-success">Classes Flexibles</h3>
                                    <p>Environnement de travail aménagé pour une pédagogie différenciée et active.</p>
                                </div>
                            </div>
                        </div>

                        <!-- Media -->
                        <div class="col-sm-6 col-xl-4 col-xs-12">
                            <div class="media mb-6">
                                <div class="media-icon-large bg-danger mr-xl-4">
                                    <i class="fa fa-child" aria-hidden="true"></i>
                                </div>
                                <div class="media-body">
                                    <h3 class="text-danger">Classe ULIS</h3>
                                    <p>Dispositif spécialisé pour l'inclusion d'élèves en situation de handicap.</p>
                                </div>
                            </div>
                        </div>

                        <!-- Media -->
                        <div class="col-sm-6 col-lg-4 col-xs-12">
                            <div class="media mb-6">
                                <div class="media-icon-large bg-info mr-xl-4">
                                    <i class="fa fa-cutlery" aria-hidden="true"></i>
                                </div>
                                <div class="media-body">
                                    <h3 class="text-info">Cuisine Traditionnelle</h3>
                                    <p>Des repas cuisinés sur place afin de bien manger pour bien grandir.</p>
                                </div>
                            </div>
                        </div>

                        <!-- Media -->
                        <div class="col-sm-6 col-xl-4 col-xs-12">
                            <div class="media mb-6">
                                <div class="media-icon-large bg-purple mr-xl-4">
                                    <i class="fa fa-heart" aria-hidden="true"></i>
                                </div>
                                <div class="media-body">
                                    <h3 class="text-purple">Activités Sportives</h3>
                                    <p>Un enseignant dédié à la pratique de l'éducation physique et sportive.</p>
                                </div>
                            </div>
                        </div>

                        <!-- Media -->
                        <div class="col-sm-6 col-xl-4 col-xs-12">
                            <div class="media mb-6">
                                <div class="media-icon-large bg-warning mr-xl-4">
                                    <i class="fa fa-language" aria-hidden="true"></i>
                                </div>
                                <div class="media-body">
                                    <h3 class="text-warning">Anglais</h3>
                                    <p>Les classes, du CP au CM2, bénéficient d'une heure par semaine d'enseignement de l'anglais.</p>
                                </div>
                            </div>
                        </div>

                        <!-- Media -->
                        <div class="col-sm-6 offset-sm-3 col-xl-4 offset-xl-4 col-xs-12">
                            <div class="media mb-7">
                                <div class="media-icon-large bg-pink mr-xl-4">
                                    <i class="fa fa-church" aria-hidden="true"></i>
                                </div>
                                <div class="media-body">
                                    <h3 class="text-pink">Pastorale</h3>
                                    <p>Culture chrétienne en classe et enseignement du catéchisme proposé à partir du CE2.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- ====================================
            --	CALL TO ACTION
            ===================================== -->
            <section class="py-9 bg-parallax" v-bind:style="{ 'background-image': 'url(' + require('../assets/img/background/background-img-2.jpg') + ')' }">
                <div class="container">
                    <div class="wow fadeInUp">
                        <div class="section-title justify-content-center">
                            <h2 class="text-white text-center">Besoin de plus d'Information?</h2>
                        </div>
                        <div class="text-center">
                            <p class="text-white font-size-18 mb-0">Contactez nous!</p>
                            <a href="#contactUs" class="btn btn-danger shadow-sm text-uppercase mt-4">
                                <i class="fa fa-phone mr-2" aria-hidden="true"></i>Contact
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <!-- ====================================
            ---	COURSES SECTION
            ===================================== -->
            <section class="py-9" id="courses">
                <div class="container">
                    <div class="section-title justify-content-center mb-4 mb-md-8 wow fadeInUp">
                        <span class="shape shape-left bg-info"></span>
                        <h2 class="text-danger">Nos Activités</h2>
                        <span class="shape shape-right bg-info"></span>
                    </div>
                    <!-- Card -->
                    <div class="row wow fadeInUp justify-content-center">
                        <div class="col-sm-6 col-xs-12 col-lg-2 activity mx-2">
                            <div class="card mh-460 pt-2 mb-6">
                                <a href="#courses" class="position-relative">
                                    <img class="card-img-top lazy-load img-fluid" :data-src="require('../assets/img/courses/cirque.jpg')" alt="Card image">
                                </a>
                                <div class="card-body border-top-5 px-3 border-warning">
                                    <h3 class="card-title">
                                        <span class="text-warning text-capitalize d-block text-truncate">Atelier Cirque</span>
                                    </h3>
                                    <ul class="list-unstyled text-muted">
                                        <li class="mb-1">
                                            <i class="fa fa-calendar-o mr-2" aria-hidden="true"></i>Toutes classes 
                                        </li>
                                    </ul>

                                    <p>Activité artistique, éducative et ludique, parfaitement adaptée au développement des jeunes enfants.</p>
                                </div>
                            </div>
                        </div>

                        <!-- Card -->
                        <div class="col-sm-6 col-xs-12 col-lg-2 activity mx-2">
                            <div class="card mh-460 pt-2 mb-6">
                                <a href="#courses" class="position-relative">
                                    <img class="card-img-top lazy-load img-fluid" :data-src="require('../assets/img/courses/judo.jpg')" alt="Card image">
                                </a>
                                <div class="card-body border-top-5 px-3 border-success">
                                    <h3 class="card-title">
                                        <span class="text-success text-capitalize d-block text-truncate">Judo</span>
                                    </h3>
                                    <ul class="list-unstyled text-muted">
                                        <li class="mb-1">
                                            <i class="fa fa-calendar-o mr-2" aria-hidden="true"></i>DU CP au CM2
                                        </li>
                                    </ul>
                                    <p>Approche du Judo à l'aide de jeux et de mouvements techniques de base.</p>
                                </div>
                            </div>
                        </div>

                        <!-- Card -->
                        <div class="col-sm-6 col-xs-12 col-lg-2 activity mx-2">
                            <div class="card mh-460 pt-2 mb-6">
                                <a href="#courses" class="position-relative">
                                    <img class="card-img-top lazy-load img-fluid" :data-src="require('../assets/img/courses/theatre.jpg')" alt="Card image">
                                </a>
                                <div class="card-body border-top-5 px-3 border-danger">
                                    <h3 class="card-title">
                                        <span class="text-danger text-capitalize d-block text-truncate">Théatre</span>
                                    </h3>
                                    <ul class="list-unstyled text-muted">
                                        <li class="mb-1">
                                            <i class="fa fa-calendar-o mr-2" aria-hidden="true"></i>Du CE1 au CM2
                                        </li>
                                    </ul>
                                    <p>Initiation au jeu théâtral développant l'expression orale, le maintien et la confiance en soi.</p>
                                </div>
                            </div>
                        </div>

                        <!-- Card -->
                        <div class="col-sm-6 col-xs-12 col-lg-2 activity mx-2">
                            <div class="card mh-460 pt-2 mb-6">
                                <a href="#courses" class="position-relative">
                                    <img class="card-img-top lazy-load img-fluid" :data-src="require('../assets/img/courses/art-graphique.jpg')" alt="Card image">
                                </a>
                                <div class="card-body border-top-5 px-3 border-info">
                                    <h3 class="card-title">
                                        <span class="text-info text-capitalize d-block text-truncate">Arts Plastiques</span>
                                    </h3>
                                    <ul class="list-unstyled text-muted">
                                        <li class="mb-1">
                                            <i class="fa fa-calendar-o mr-2" aria-hidden="true"></i>Toutes classes
                                        </li>
                                    </ul>
                                    <p>Des projets motivants et ludiques pour stimuler la créativité, l'imagination et la sensibilité.</p>
                                </div>
                            </div>
                        </div>
                        
                        <!-- Card -->
                        <div class="col-sm-6 col-xs-12 col-lg-2 activity mx-2">
                            <div class="card mh-460 pt-2 mb-6">
                                <a href="#courses" class="position-relative">
                                    <img class="card-img-top lazy-load img-fluid" :data-src="require('../assets/img/courses/music-initiation.jpg')" alt="Card image">
                                </a>
                                <div class="card-body border-top-5 px-3 border-info">
                                    <h3 class="card-title">
                                        <span class="text-info text-capitalize d-block text-truncate">Immersion<br />musicale</span>
                                    </h3>
                                    <ul class="list-unstyled text-muted">
                                        <li class="mb-1">
                                            <i class="fa fa-calendar-o mr-2" aria-hidden="true"></i>Toutes classes
                                        </li>
                                    </ul>
                                    <p>Plonger au coeur de l'univers de la musique avec le travail de la voix, l'expression corporelle, la création scénique et musicale.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- ====================================
            --- SCHOOL INFORMATIONS SECTION
            ===================================== -->
            <section class="py-9" id="school" style="display: none;">
                <div class="container">
                    <div class="section-title justify-content-center mb-2 mb-md-8 wow fadeInUp">
                        <span class="shape shape-left bg-info"></span>
                        <h2 class="text-danger">L'Ecole</h2>
                        <span class="shape shape-right bg-info"></span>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <h3 id="slbp" class="text-success text-center mt-4 font-weight-bold">Saint Louis Blaise Pascal</h3>
                            <div class="media-body">
                                <div class="font-weight-bold">L'Ecole Saint-Louis Blaise Pascal est un établissement Privé Catholique d'Enseignement, sous Contrat d'Association avec l'Etat.</div>
                                <div class="mt-2">
                                    <u>CHEFFE D'ETABLISSEMENT :</u> Madame Bénédicte TASSI<br />
                                    <u>SECRETARIAT :</u> Madame Valérie TURPIN<br />
                                    <u>COMPTABILITE :</u> Madame Valérie JOUAS<br />
                                </div>
                            </div>
                            <div id="historique" class="media-body mt-4">
                                <div class="text-left">
                                    Initialement, l’école Blaise Pascal appartenait à une Demoiselle qui accueillait un internat de Jeunes filles. 
                                    L’école se situait alors rue Georges Clémenceau, dans des locaux paroissiaux. 
                                    Dans les années 60, la Demoiselle, suite à la loi Debré, a vendu ses locaux à l’enseignement catholique. 
                                    D’où le nom actuel de votre école.<br />
                                    Depuis ce temps, votre établissement est sous tutelle diocésaine. L’internat a fermé en 1984.<br />
                                    Pendant la dernière guerre, les allemands ayant réquisitionnés l’école, ont construit un blockhaus, 
                                    sous l’actuelle pelouse, qui, maintenant sert de lieu de rangement pour l’association des parents d’élèves.
                                    Les premiers locaux datent des années 1870. Puis progressivement, les bâtiments se sont étendus 
                                    et les dernières constructions datent de 1995.<br /> 
                                    Depuis, chaque année, grâce aux aides conjuguées des associations de l’Enseignement catholique, 
                                    de l’APEL et des différents donateurs, nous poursuivons l’effort de rénovation des locaux : 
                                    changement des fenêtres, peintures des classes, installation de jeux etc... 
                                    

                                </div>
                            </div>
                            <div class="media-body text-left mt-4">
                                <h5 class="text-pink font-weight-bold">ACCUEIL :</h5>
                                Elle accueille les enfants de 3 ans à 11 ans et est donc composée de 13 classes :
                                <ul>
                                    <li>3 classes maternelles</li>
                                    <li>10 classes primaires ( 2 par niveau )</li>
                                    <li>1 regroupement d'adaptation (avec 1 enseignant spécialisé dans l'aide aux enfants en grosses difficultés scolaires)</li>
                                    <li>1 ULIS (Unité Localisée d'Inclusion Scolaire), dispositif réservé aux enfants bénéficiant d'une notification de la MDPH (Maison Départementale des Personnes Handicapées)</li>
                                </ul>
                            </div>
                            <div class="media-body text-left mt-4">
                                <h5 class="text-warning font-weight-bold">COMMODITES :</h5>
                                L'école est ouverte de 7h30 à 19h00 les lundi, mardi, jeudi, vendredi.
                                Nous proposons donc une garderie le matin, l'étude et une garderie le soir.<br />
                                <!-- <div><a href="#horaires" class="badge badge-success" @click.prevent="display('horaires')">Voir le détail des horaires... </a></div> -->

                                <div id="horaires">
                                    <section>
                                        <div class="container">
                                            <div class="row text-center">
                                                <div class="col-lg-12 text-center">
                                                    <h3 class="text-primary mt-4 font-weight-bold">Horaires</h3>

                                                    <div class="wrapperHoraires pt-1  pb-4 rounded bg-warning col-lg-12 text-center">
                                                        <div class="matin mt-4">
                                                            <div class="horaire">
                                                                <div class="heure">07h30 - 08h20</div>
                                                                <div class="heureDetail">Garderie accueil dans la cantine, accès 6 rue de Verdun.</div>
                                                            </div>
                                                            <div class="horaire">
                                                                <div class="heure">08h20 - 08h30<br /><br /></div>
                                                                <div class="heureDetail">
                                                                    <u>Maternelles :</u> entrée par la rue de l'Insurrection Parisienne.
                                                                    <br /><u>Primaires :</u> entrée par le 6 rue de Verdun.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="journee mt-4">
                                                            <div class="horaire">
                                                                <div class="heure">08h30</div><div class="heureDetail">Sonnerie, montée dans les classes.</div>
                                                            </div>
                                                            <div class="horaire">
                                                                <div class="heure">10h00 - 10h20</div><div class="heureDetail">Récréation des primaires.</div>
                                                            </div>
                                                            <div class="horaire">
                                                                <div class="heure">10h30 - 11h</div><div class="heureDetail">Récréation des maternelles.</div>
                                                            </div>
                                                            <div class="horaire">
                                                                <div class="heure">11h35 - 11h45</div><div class="heureDetail">Sortie des enfants de maternelle qui déjeunent à la maison, rue de l'Insurrection Parisienne.</div>
                                                            </div>
                                                            <div class="horaire">
                                                                <div class="heure">11h45 - 11h55</div><div class="heureDetail">Sortie des primaires, rue de Verdun. Les parents attendent les enfants à l'extérieur de l'école.</div>
                                                            </div>
                                                            <div class="horaire">
                                                                <div class="heure">13h05</div><div class="heureDetail">Ouverture des portails (maternelles, primaires).</div>
                                                            </div>
                                                            <div class="horaire">
                                                                <div class="heure">13h15</div><div class="heureDetail">Sonnerie, montée dans les classes.</div>
                                                            </div>
                                                            <div class="horaire">
                                                                <div class="heure">14h30 - 14h50</div><div class="heureDetail">Récréation des primaires.</div>
                                                            </div>
                                                            <div class="horaire">
                                                                <div class="heure">15h15 - 15h45</div><div class="heureDetail">Récréation des maternelles.</div>
                                                            </div>
                                                            <div class="horaire">
                                                                <div class="heure">16h05 - 16h15</div>
                                                                <div class="heureDetail"><u>Maternelles :</u> sortie par la rue de l'Insurrection Parisienne.</div>
                                                            </div>
                                                            <div class="horaire">
                                                                <div class="heure">16h20 - 16h30<br /><br /></div>
                                                                <div class="heureDetail">
                                                                    <u>Primaires :</u> sortie par le 6 rue de Verdun.
                                                                    <div>Les parents attendent leur(s) enfant(s) <u>sur le trottoir</u>.</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="soir mt-4">
                                                            <div class="horaire">
                                                                <div class="heure">16h30-17h45</div>
                                                                <div class="heureDetail"><u>Primaires :</u> étude dirigée assurée par des enseignants (toute heure commencée doit étre terminée).</div>
                                                            </div>
                                                            <div class="horaire">
                                                                <div class="heure">16h30-17h45</div>
                                                                <div class="heureDetail">
                                                                    Garderie des maternelles.
                                                                    <p class="red">
                                                                        <br />ATTENTION
                                                                        <br />Aucun parent ne pourra entrer dans l'établissement avant l'ouverture du portail au 6 rue de Verdun, à 17h45.<br />
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="horaire">
                                                                <div class="heure">17h45</div>
                                                                <div class="heureDetail">
                                                                    Sortie des ateliers par le portail au 6 rue de Verdun.
                                                                    Les parents attendent leur(s) enfant(s) <u>sur le trottoir</u>.
                                                                </div>
                                                            </div>
                                                            <div class="horaire">
                                                                <div class="heure">17h45-19h00</div>
                                                                <div class="heureDetail">Garderie</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div class="media-body text-left mt-4">
                                <h5 class="text-pink font-weight-bold">RESTAURATION :</h5>
                                La restauration traditionnelle, confectionnée sur place, est assurée par la société SCOLAREST.
                                Les maternelles ont un menu unique, servi à table.
                                Les primaires disposent d'un self service avec menus au choix (3 choix possibles).
                                Les menus sont consultables sur notre site et sont affichés dans les classes.<br />
                            </div>
                            <div class="media-body text-left mt-4">
                                <h5 class="text-warning font-weight-bold">ACTIVITES SCOLAIRES :</h5>
                                Nous suivons les programmes officiels de l'Education Nationale.
                                L'enseignement de l'anglais est dispensé par les enseignants dés le Cours Préparatoire (CP).
                                L'informatique est un outil à disposition des éléves, en classe.
                                La bibliothéque est ouverte tous les aprés-midis, grâce aux parents d'éléves qui assurent l'accueil des éléves.
                                L'Education Physique et Sportive est dispensée par un professeur spécifique intervenant auprès des enfants dès la Petite Section de maternelle.<br />
                            </div>
                            <div class="media-body text-left mt-4">
                                <h5 class="text-pink font-weight-bold">ACTIVITES EXTRA-SCOLAIRES :</h5>
                                L'école, les activités suivantes :
                                <ul>
                                    <li>Atelier Cirque</li>
                                    <li>Judo</li>
                                    <li>Théâtre</li>
                                    <li>Arts Plastiques</li>
                                    <li>Immersion musicale</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <h3 class="text-success text-center mt-4 font-weight-bold">Projet éducatif</h3>
                            <div class="media-body text-left mt-4">
                                L'école Saint-Louis Blaise Pascal est un établissement privé catholique, sous contrat d'association avec l'état.
                                Il accueille environ quatre cents élèves répartis sur treize classes, s'échelonnant de la maternelle petite section au cours moyen deuxiéme année.
                                L'éducation proposée s'adresse au plus grand nombre d'enfants, respectant ainsi les différences religieuses, intellectuelles et sociales qui reflètent la vie du quartier
                                dans lequel l'école est implantée.<br />
                                Partant de cette réalité, l'équipe éducative privilégie, entre autres, trois points essentiels :<br />
                                <ul>
                                    <li>l'aspect pastoral,</li>
                                    <li>l'aspect intellectuel,</li>
                                    <li>l'aspect humain.</li>
                                </ul>
                            </div>
                            <div class="media-body text-left mt-4">
                                <h5 class="text-warning font-weight-bold">L'ASPECT PASTORAL</h5>
                                Les enfants inscrits dans notre école catholique bénéficient tous d'un éveil religieux qui est assuré par la communauté éducative, sous forme de culture religieuse
                                nécessaire à l'ouverture d'esprit de toute personne.<br />
                                Cet éveil consiste à sensibiliser les enfants de toutes religions au fait que Dieu aime tous les hommes quelles que soient leur origine et leur façon d'exprimer leur foi.
                                Notre Mission évangélique est basée sur la notion d'Accueil, que nous retrouvons dans les textes bibliques et en référence au statut de l'Enseignement Catholique
                                promulgué par les évêques de France: "... notre école catholique se présente comme une communauté chrétienne ayant pour base un projet éducatif enraciné dans le Christ
                                et son Evangile...".<br />
                                Nous essayons d'annoncer aux jeunes que Dieu a fait Alliance avec l'Homme et que ce dernier est libre de répondre à cet Amour. Ce message d'espérance est communiqué
                                aux enfants sous diverses formes durant l'année : célébrations, temps de prière et de réflexion ; et ce, en tenant compte des différentes sensibilités religieuses des familles.
                                L'enfant est amené à réfléchir, avec les adultes, sur sa relation avec Dieu, à travers les religions représentées dans notre communauté.<br />
                            </div>
                            <div class="media-body text-left mt-4">
                                <h5 class="text-pink font-weight-bold">L'ASPECT INTELLECTUEL</h5>

                                Nous suivons les instructions officielles de l'Education Nationale.<br />
                                Notre établissement accepte tous les enfants, sans tenir compte de leur niveau scolaire. Nous travaillons en essayant de donner à chacun ce qu'il doit attendre de nous.
                                Pour ce faire, nous mettons en oeuvre des démarches pédagogiques appropriées, privilégiant les liens entre les différentes classes et assurant, ainsi, la continuité
                                entre tous les cycles.<br />
                                Des cours d'éducation physique et sportive sont assurés, dès la seconde année de classe maternelle, par un professeur spécifique. Cette discipline nous apparaît,
                                en effet, essentielle pour l'équilibre de la personnalité.<br />
                                Des séances d'anglais, destinées à sensibiliser les enfants,  sont également dispensées, en temps scolaire, dès la petite section , par deux professeurs d'Anglais.
                                Nous essayons ainsi d'ouvrir les jeunes sur les spécificités du monde anglo-saxon.<br />
                                Les classes sont constituées de façon hétérogène afin que la stimulation fasse progresser chaque éléve selon son rythme et ses capacités.
                                Nous nous attachons à établir des relations entre les professeurs de collèges et ceux de cours moyen en essayant de coordonner au mieux nos actions.
                                Nous souhaitons une collaboration étroite et active entre les enseignants et les parents pour le suivi des éléves qui demeurent acteurs de leur développement.
                                Il nous paraît, en effet, très important que l'enfant perçoive le soutien de l'ensemble des éducateurs qui l'entourent, à l'école et la maison.<br />
                            </div>
                            <div class="media-body text-left mt-4">
                                <h5 class="text-warning font-weight-bold">L'ASPECT HUMAIN</h5>
                                L'école ne se substitue pas à la famille. Elle participe à l'éducation des enfants, en rapport étroit avec les parents.<br />
                                Au sein de l'établissement existent les structures nécessaires à l'épanouissement de chacun en faisant apparaître des facteurs essentiels qui sont :
                                <ul>
                                    <li>le respect de l'autre,</li>
                                    <li>le sens des responsabilités,</li>
                                    <li>l'ouverture d'esprit,</li>
                                    <li>le sens critique,</li>
                                    <li>l'envie de se dépasser.</li>
                                </ul>
                                <br />
                                Ainsi, les enfants évoluent dans un cadre où ils développent leur personnalité en faisant l'expérience de la vie en collectivité. Cela suppose une certaine discipline
                                à laquelle les enfants adhèrent en connaissance de cause (conseil d'élèves - règlement intérieur - vie de classe).<br />
                                La communauté éducative met en oeuvre des temps de rencontre afin que chacun trouve sa place et qu'un dialogue constructif s'instaure entre les intervenants
                                (réunions pédagogiques, journée portes ouvertes,  fêtes diverses, célébrations, ...).<br />
                                Les parents participent aux différentes activités telles que :<br />
                                <ul>
                                    <li>la bibliothèque,</li>
                                    <li>l'informatique,</li>
                                    <li>les travaux manuels,</li>
                                    <li>les sorties éducatives et sportives,</li>
                                    <li>les préparations des fêtes.</li>
                                </ul>
                                <br />
                                Ainsi, nous souhaitons donner le plus de chances possibles à tous les enfants en participant à leur éducation spirituelle, humaine et intellectuelle,
                                sans nous substituer aux parents qui sont les premiers éducateurs.
                                Nous insistons sur une collaboration étroite entre enseignants et parents, sur la notion de respect du rôle de chacun, dans un climat de confiance mutuelle.
                            </div>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-lg-12">
                            <h3 class="text-success text-center mt-4 font-weight-bold">Règlement</h3>
                            <div class="media-body text-left mt-4">
                                Ce règlement, établi en conseil d'établissement, est signé par chaque éléve en début d'année scolaire et affiché dans les différents lieux de passage.<br /><br />
                                <div>
                                    <div>Le règlement de l'école est téléchargeable en cliquant sur le bouton ci-dessous.</div>
                                    <div><a class="btn btn-success mt-2" href="https://api.slbp.fr/StaticFiles/Règlement_SLBP.pdf" target="_blank" >Règlement Intérieur</a></div>
                                </div>
                                Les enfants inscrits à Saint-Louis Blaise Pascal s'engagent à suivre les règles suivantes :<br /><br />
                                
                                <h4 class="my-2 text-warning">Cycle 1</h4>
                                
                                    <h5 class="mt-3"><b>En classe, en cours de sport :</b></h5>
                                    J'écoute avec attention les professeurs.<br />
                                    Je prends la parole lorsque l'on m’invite à le faire.<br />
                                    Je respecte mon matériel et le matériel collectif.<br />
                                    J'ai ma tenue de sport et un change (uniquement en GS).<br />
                                    Je ne dis pas de gros.<br />
                                    Je fais des efforts dans mon travail.<br />
                                    Je demande de l'aide.<br />

                                    <h5 class="mt-3"><b>Pendant les récréations :</b></h5>
                                    Je vais en récréation pour jouer.<br />
                                    Je joue collectivement dans le calme.<br />
                                    Je respecte les adultes qui surveillent.<br />
                                    Je respecte mes camarades (verbalement et physiquement).<br />
                                    Je ne joue pas à des jeux dangereux.<br />
                                    Je ne harcèle pas mes camarades.<br />
                                    Si je suis témoin d'actes interdits j'alerte immédiatement les adultes.<br />

                                    <h5 class="mt-3"><b>A la cantine :</b></h5>
                                    Je tiens compte des conseils des surveillants pour manger équilibré.<br />
                                    A table, je mange dans le calme et la convivialité.<br />
                                    J'apprends à ne pas gaspiller la nourriture.<br />
                                    Je me place ou le surveillant me le demande.<br />


                                    <h5 class="mt-3"><b>En sortie à l'extérieur de l'école :</b></h5>
                                    (Sorties pédagogiques, classes découvertes...)<br />
                                    Je suis calme dans les transports.<br />
                                    Je respecte toutes les personnes qui me proposent une activité.<br />
                                    J'applique toutes les règles en vigueur à l'école.<br />

                                    <h5 class="mt-3"><b>Les objets de valeur :</b></h5>
                                    Je n'emporte pas d'objets de valeur, si je les perds ou s'ils se dégradent, l'école ne les remplace pas.<br /><br />

                                    <h4 class="my-2 text-warning">Cycle 2 et cycle 3</h4>

                                    <h5 class="mt-3"><b>En classe, en cours de sport et d'anglais :</b></h5>
                                    J'écoute avec attention les professeurs.<br />
                                    Je prends la parole lorsque l'on m’invite à le faire.<br />
                                    Je respecte mon matériel celui de mes camarades et le matériel collectif.<br />
                                    J'ai ma tenue de sport et un change dans un sac.<br />
                                    Je ne dis pas de gros mots.<br />
                                    Je donne le meilleur de moi-même dans le travail.<br />
                                    Je demande de l'aide si besoin.<br />

                                    <h5 class="mt-3"><b>Pendant les récréations :</b></h5>
                                    Je vais en récréation pour jouer et me détendre.<br />
                                    J'ai le droit d'emporter des jeux qui me permettent de jouer collectivement dans le calme (ils sont sous ma responsabilité en cas de perte) sauf en CP.<br />
                                    Je respecte les adultes qui surveillent.<br />
                                    Je respecte mes camarades (verbalement et physiquement).<br />
                                    Je n'organise pas de jeux dangereux.<br />
                                    Je ne harcèle pas mes camarades.<br />
                                    Si je suis témoin d'actes interdits j'alerte immédiatement les adultes.<br />
                                    Je profite de la récréation pour passer aux toilettes me laver les mains.<br />
                                    Je laisse les toilettes propres.<br />
                                    Je ne joue pas dans les toilettes.<br />

                                    <h5 class="mt-3"><b>A la cantine :</b></h5>
                                    Je tiens compte des conseils des surveillants pour manger équilibré.<br />
                                    A table, j'échange dans le calme et la convivialité.<br />
                                    J'apprends à ne pas gaspiller la nourriture.<br />
                                    Je mange proprement.<br />
                                    Je me place ou le surveillant me le demande.<br />

                                    <h5 class="mt-3"><b>A l'étude :</b></h5>
                                    Je fais mes devoirs et j’apprends mes leçons.<br />
                                    Je demande de l’aide. Je m'avance dans mon travail.<br />
                                    Je m'occupe calmement si j'ai terminé mon travail.<br />

                                    <h5 class="mt-3"><b>En sortie à l'extérieur de l’école :</b>
                                    (Piscine, sorties pédagogiques, classes découvertes...)</h5>
                                    Je suis calme dans les transports.<br />
                                    Je respecte toutes les personnes qui me proposent une activité.<br />
                                    J'applique toutes les règles en vigueur à l'école.<br />

                                    <h5 class="mt-3"><b>Les objets de valeur :</b></h5>
                                    Le téléphone portable :<br />
                                    Si je viens avec un téléphone portable, je l'éteins dès mon arrivée à l'école je le confie à la directrice. Je le récupère au moment de quitter l'école.<br /><br />


                                    Le harcèlement :
                                    Si je pense être victime de harcèlement (subir des brimades ou de la violence de façon répétée de la part d'un ou plusieurs camarades) je dois absolument en parler avec un adulte (parents, enseignants directeurs...).<br />
                                    Si je suis témoin de harcèlement, je dois en parler absolument aux adultes.<br />
                                    Une information aura lieu chaque année sur ce sujet dans les classes ainsi que sur les dangers d'internet.<br />

                                    Dans ce cas un protocole sera mis en place par la direction avec les parents des enfants concernés.<br /><br />

                                    <h4 class="my-2 text-warning">Engagement des parents</h4>
                                    Cet engagement a pour objectif la mise en place d’une co-éducation nécessaire entre l’école et les familles dans l’intérêt des enfants.<br />
                                    « L’attitude des adultes détermine celle des enfants ».<br />

                                    <h5 class="mt-3"><b>Temps scolaire : en tant que parents</b></h5>
                                    Nous nous engageons à conduire notre enfant à l’heure à l’école.<br />
                                    Nous nous engageons à conduire régulièrement notre enfant à l’école.<br />
                                    Nous nous engageons à ne pas partir en vacances en dehors des périodes de vacances scolaires.<br />

                                    <h5 class="mt-3"><b>Tenue : en tant que parents</b></h5>
                                    Nous nous engageons à demander à notre enfant de venir à l’école dans une tenue de ville confortable et pratique (tenues trop courtes interdites, bermudas autorisés). Pas de maquillage.<br />
                                    Une blouse au choix des parents est demandée aux enfants de maternelle.<br />

                                    <h5 class="mt-3"><b>Hygiène de vie : en tant que parents</b></h5>
                                    (Afin de faciliter l’entrée dans les apprentissages).<br />
                                    Nous nous engageons à veiller sur le temps de sommeil de notre enfant afin qu’il soit le plus reposé possible à l’école.<br />
                                    Nous nous engageons à surveiller le gouter que nous donnons à notre enfant pour l’école. Privilégier les gouters faciles à manger : type compote, fruits, yaourt à boire, eau).<br />
                                    Nous nous engageons à limiter le temps de présence de notre enfant devant les écrans afin de ne pas dégrader sa capacité de concentration.<br />

                                    <h5 class="mt-3"><b>Objets emportés à l’école : en tant que parents</b></h5>
                                    Nous nous engageons à ne pas confier d’objets de valeur à notre enfant. En cas de perte ou de dégradation, l’école n'est pas tenue pour responsable et ne les remplacera pas.<br />
                                    Nous nous engageons à fournir à notre enfant tout le matériel scolaire nécessaire pour son travail en classe.<br /><br />

                                    Nous nous engageons à demander à notre enfant déteindre son portable (s’il en possède un) en arrivant à l’école et de le confier dès son arrivée à la direction jusqu’à la fin de la journée.<br /><br />

                                    Nous nous engageons à lire le règlement des enfants avec notre enfant.<br /><br />


                                <!-- Chaque élève détient un livret de comportement qu'il reçoit en début d'année.<br />
                                Tout manquement au règlement sera mentionné dans ce livret.
                                <div>
                                    <div id="comportement">
                                        <div class="position-relative w-100 media media-hoverable justify-content-center" @click="livretClick('1.jpg')" style="cursor:pointer;"><img class="media-img img-fluid w-100 m-2" src="../assets/img/comportement/1.jpg" alt="Livret comportement SLBP 1"/></div>
                                        <div class="position-relative w-100 media media-hoverable justify-content-center" @click="livretClick('2.jpg')" style="cursor:pointer;"><img class="media-img img-fluid w-100 m-2" src="../assets/img/comportement/2.jpg" alt="Livret comportement SLBP 2"/></div>                       
                                        <div class="position-relative w-100 media media-hoverable justify-content-center" @click="livretClick('3.jpg')" style="cursor:pointer;"><img class="media-img img-fluid w-100 m-2" src="../assets/img/comportement/3.jpg" alt="Livret comportement SLBP 3"/></div>                       
                                        <div class="position-relative w-100 media media-hoverable justify-content-center" @click="livretClick('4.jpg')" style="cursor:pointer;"><img class="media-img img-fluid w-100 m-2" src="../assets/img/comportement/4.jpg" alt="Livret comportement SLBP 4"/></div>                       
                                    </div>
                                    <Modal v-if='showComportement' v-bind:class="{show: showComportement}" @close="showComportement = false">
                                        <div slot='body' class="slotComportement">
                                            <img v-bind:src="largeComportement.large" class="img-fluid largeComportement"/>
                                        </div>        
                                    </Modal>  
                                </div>  -->
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- ====================================
            ---	SUBSCRIPTION SECTION
            ===================================== -->
            <section class="py-6" id="subscription" style="display: none;">
                <div class="container">
                    <div class="section-title justify-content-center mb-2 mb-md-8 wow fadeInUp">
                        <span class="shape shape-left bg-info"></span>
                        <h2 class="text-danger">Inscription</h2>
                        <span class="shape shape-right bg-info"></span>
                    </div>
                    <div class="mt-8 row">
                        <div class="media-body col-12 col-sm-12 pt-3">
                            <h3 class="text-success text-center">Modalités d'inscription</h3>
                            <div class="text-left">
                                <ul class="noPuce">
                                    <li><i class="fa fa-check mr-2" aria-hidden="true"></i>Télécharger le dossier de pré-inscription en cliquant sur le bouton ci-dessous.</li>
                                    <li>
                                        <i class="fa fa-check mr-2" aria-hidden="true"></i>
                                        Déposer le dossier de pré-inscription complété et accompagné d'une lettre de motivation, dans la boite aux lettres de l'école ou par email.
                                    </li>
                                </ul>
                                <br />Le secrétariat est ouvert les lundi, mardi, jeudi et vendredi de 8h45 à 11h30 et de 14h00 à 16h45.
                                Après étude du dossier et en fonction des places disponibles, un rendez-vous vous sera proposé pour l'inscription définitive.
                                <div class="red"><br /><strong>La présence à ce rendez-vous, <u>de l'enfant et de ses deux parents</u> est obligatoire.</strong></div>
                                <br />Suite à l'entretien, si votre projet en tant que parents rejoint celui de l'établissement, alors seulement, votre enfant pourra être inscrit définitivement.<br />                                
                                Vous recevrez ensuite un contrat de scolarisation, qui devra être resigné <u>chaque année</u> par le Chef d'Etablissement et par vous-même afin de valider la réinscription de l'enfant.<br />
                                En juin, il faudra fournir un certificat de radiation de l'enfant provenant de l'ancien établissement, l'avis de passage en classe supérieure ainsi que le dernier Livret Scolaire.
                            </div>
                        </div>
                        <div class="media-body col-12 col-sm-12 pt-3">
                            <h3 class="text-success text-center">Pièces à fournir le jour de l'entretien</h3>
                            <div class="text-left">
                                <ul class="noPuce">
                                    <li>
                                        <i class="fa fa-check mr-2" aria-hidden="true"></i>
                                        POUR TOUTES LES CLASSES
                                        <br />- le livret de famille + les photocopies des pages parents et de tous les enfants,
                                        <br />- le carnet de santé + la photocopie des vaccinations,
                                        <br />- Un chéque de 140 € pour les arrhes, libellé à l'ordre de: OGEC Saint-Louis Blaise Pascal.
                                        <br />- Un chéque de 40 € pour les frais d'inscription, libellé à l'ordre de: OGEC Saint-Louis Blaise Pascal.
                                        <br /><div class="red"><strong><br />Attention! En cas de désistement, les arrhes versées à l'inscription ou à la réinscription ne sont pas remboursables 
                                            <u>quel que soit le motif</u>.<br /></strong><br /></div>
                                    </li>
                                    <li>
                                        <i class="fa fa-check mr-2" aria-hidden="true"></i>
                                        POUR LES CLASSES PRIMAIRES, FOURNIR EN SUPPLEMENT :
                                        <br />- le carnet scolaire,
                                        <br />- le certificat de radiation,
                                        <br />- l'avis de passage en classe supérieure.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row"><div class="red col-lg-12 text-center"><strong><br />Le dossier complet est nécessaire pour l'inscription définitive de l'enfant.</strong></div></div>
                    <div class="row">
                        <div class="mt-8 col-lg-12">
                            <h3 class="text-success text-center">Dossier de pré-inscription</h3>
                            <div>
                                <div>Le dossier de pré-inscription est téléchargeable en cliquant sur le bouton ci-dessous.</div>
                                <a class="btn btn-success mt-2" id="divDossier" href="https://api.slbp.fr/StaticFiles/PreInscription_SaintLouisBlaisePascal.pdf" target="_blank" >Dossier</a>
                            </div>
                        </div>
                        <div class="mt-8 col-lg-12">
                            <h3 class="text-success text-center">Tarifs</h3>
                            <div>
                                <div>Les tarifs de la scolarité sont téléchargeables en cliquant sur le bouton ci-dessous.</div>
                                <a class="btn btn-success mt-2" id="divTarifs" href="https://api.slbp.fr/StaticFiles/Tarif_SaintLouisBlaisePascal.pdf" target="_blank" >Tarifs</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- ====================================
            ---	INFO CLASSES SECTION
            ===================================== -->
            <section class="pt-3 pb-9" id="classes" style="display: none;">
                <div class="container">
                    <div class="section-title justify-content-center mb-2 mb-md-8 wow fadeInUp">
                        <span class="shape shape-left bg-info"></span>
                        <h2 class="text-danger">Info classes</h2>
                        <span class="shape shape-right bg-info"></span>
                    </div>
                    <div class="media-body col-12 col-sm-12 pt-3">
                        <h3 class="text-success text-center">Fournitures</h3>
                        <div class="row text-center supplies align-self-center">
                            <div class="my-2 col-lg-3 col-md-4 col-sm-6 col-6">                                
                                    <div class="newsClasses bg-info scrolling" >                                    
                                        <h4 class="text-white py-6 font-size-20">PS</h4>
                                        <a v-if="classes.ps" v-bind:href="classes.ps" target="_blank">Télécharger</a>
                                    </div>                                
                            </div>
                            <div class="my-2 col-lg-3 col-md-4 col-sm-6 col-6">                                
                                    <div class="newsClasses bg-success scrolling" >                                    
                                        <h4 class="text-white py-6 font-size-20">MS</h4>
                                        <a v-if="classes.ms" v-bind:href="classes.ms" target="_blank">Télécharger</a>
                                    </div>                                
                            </div>
                            <div class="my-2 col-lg-3 col-md-4 col-sm-6 col-6">                                
                                    <div class="newsClasses bg-warning scrolling" >                                    
                                        <h4 class="text-white py-6 font-size-20">GS</h4>
                                        <a v-if="classes.gs" v-bind:href="classes.gs" target="_blank">Télécharger</a>
                                    </div>                                
                            </div>
                            <div class="my-2 col-lg-3 col-md-4 col-sm-6 col-6">                                
                                    <div class="newsClasses bg-danger scrolling" >                                    
                                        <h4 class="text-white py-6 font-size-20">CP</h4>
                                        <a v-if="classes.cp" v-bind:href="classes.cp" target="_blank">Télécharger</a>
                                    </div>                                
                            </div>
                            <div class="my-2 col-lg-3 col-md-4 col-sm-6 col-6">                                
                                    <div class="newsClasses bg-success scrolling" >                                    
                                        <h4 class="text-white py-6 font-size-20">CE1</h4>
                                        <a v-if="classes.ce1" v-bind:href="classes.ce1" target="_blank">Télécharger</a>
                                    </div>                                
                            </div>
                            <div class="my-2 col-lg-3 col-md-4 col-sm-6 col-6">                                
                                    <div class="newsClasses bg-danger scrolling" >                                    
                                        <h4 class="text-white py-6 font-size-20">CE2</h4>
                                        <a v-if="classes.ce2" v-bind:href="classes.ce2" target="_blank">Télécharger</a>
                                    </div>                                
                            </div>
                            <div class="my-2 col-lg-3 col-md-4 col-sm-6 col-6">                                
                                    <div class="newsClasses bg-info scrolling" >                                    
                                        <h4 class="text-white py-6 font-size-20">CM1</h4>
                                        <a v-if="classes.cm1" v-bind:href="classes.cm1" target="_blank">Télécharger</a>
                                    </div>                                
                            </div> 
                            <div class="my-2 col-lg-3 col-md-4 col-sm-6 col-6">                                
                                    <div class="newsClasses bg-warning scrolling" >                                    
                                        <h4 class="text-white py-6 font-size-20">CM2</h4>
                                        <a v-if="classes.cm2" v-bind:href="classes.cm2" target="_blank">Télécharger</a>
                                    </div>                                
                            </div>
                            <div class="my-2 col-lg-3 col-md-4 col-sm-6 col-6">                                
                                    <div class="newsClasses bg-success scrolling" >                                    
                                        <h4 class="text-white py-6 font-size-20">ULIS</h4>
                                        <a v-if="classes.ulis" v-bind:href="classes.ulis" target="_blank">Télécharger</a>
                                    </div>                                
                            </div>                                                                                                                                                                                                                              
                        </div>
                    </div>
                </div>
            </section>

            <!-- ====================================
            ---	TEACHERS SECTION
            ===================================== -->
            <section class="pt-9 pb-2 py-md-10 bg-purple" id="teachers" v-bind:style="{ 'background-image': 'url(' + require('../assets/img/background/avator-bg.png') + ')' }">
                <div class="container">
                    <div class="section-title justify-content-center mb-2 mb-md-8 wow fadeInUp">
                        <span class="shape shape-left bg-danger"></span>
                        <h2 class="text-white">L'équipe pédagogique</h2>
                        <span class="shape shape-right bg-danger"></span>
                    </div>

                    <carousel>
                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/benedicte.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body text-center">
                                <a class="font-weight-medium d-block" href="#teachers">Bénédicte<br />TASSI</a>
                                <span class="text-white">Directrice</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Valérie<br />TURPIN</a>
                                <span class="text-white">Secrétaire</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Valérie<br />JOUAS</a>
                                <span class="text-white">Comptable</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Khady<br />FALL</a>
                                <span class="text-white">Restauration</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/laurenceD.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Laurence<br />DAURELLE</a>
                                <span class="text-white">P.S. & G.S.</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Sandy<br />SETTE</a>
                                <span class="text-white">M.S.</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/guillaume.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Guillaume<br />BOSSERT</a>
                                <span class="text-white">P.S. & G.S.</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Nadège<br />GROULT</a>
                                <span class="text-white">CP</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Eva<br />KINSA</a>
                                <span class="text-white">CP</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Dominique<br />BRILL</a>
                                <span class="text-white">CE1</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Claire<br />CHARLEUX</a>
                                <span class="text-white">CE1</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/male.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Adama<br /></a>
                                <span class="text-white">CE2</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/jessy.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Jessy<br />BOUTROS</a>
                                <span class="text-white">CE2</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Emilie<br /></a>
                                <span class="text-white">CM1</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/souhayla.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Souhayla<br />MSADEK</a>
                                <span class="text-white">CM1</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Julie<br />GRAFFAN</a>
                                <span class="text-white">CM2</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Pauline<br />CHATAGNON</a>
                                <span class="text-white">CM2</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Géraldine<br /></a>
                                <span class="text-white">CM2</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Laëtitia<br />LE HAN</a>
                                <span class="text-white">Ulis</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/EmilieL.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Emilie<br />LAURENS</a>
                                <span class="text-white">Auxiliaire pédagogique</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Géraldine<br /></a>
                                <span class="text-white">AESH</span>
                            </div>
                        </div>
                        
                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Nouria<br /></a>
                                <span class="text-white">AESH</span>
                            </div>
                        </div>
                        
                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Narjes<br /></a>
                                <span class="text-white">AESH</span>
                            </div>
                        </div>
                        
                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Melody<br /></a>
                                <span class="text-white">AESH</span>
                            </div>
                        </div>
                        
                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Frédérique<br /></a>
                                <span class="text-white">AESH</span>
                            </div>
                        </div>
                        
                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Irena<br /></a>
                                <span class="text-white">AESH</span>
                            </div>
                        </div>
                        <!--<div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Guadalupe<br />SCARAMELLI</a>
                                <span class="text-white">Anglais</span>
                            </div>
                        </div>-->

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/male.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Yannis<br />ALOÏSOS</a>
                                <span class="text-white">Sport</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Valérie<br />DEBICHE</a>
                                <span class="text-white">Polyvalente</span>
                            </div>
                        </div>  

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Soumaya<br />AMMAR</a>
                                <span class="text-white">ASEM</span>
                            </div>
                        </div>

                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Nathalie<br />LEGRAND</a>
                                <span class="text-white">ASEM</span>
                            </div>
                        </div>   
                        
                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/female.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Stéphanie<br />PINHEIRO</a>
                                <span class="text-white">ASEM</span>
                            </div>
                        </div> 
                                                
                        <div class="card card-hover card-transparent shadow-none" style="border: none;">
                            <div class="card-img-wrapper position-relative shadow-sm rounded-circle mx-auto">
                                <img class="card-img-top rounded-circle lazy-load" :data-src="require('../assets/img/avator/male.jpg')" alt="carousel-img" />
                            </div>
                            <div class="card-body position-relative text-center pb-0">
                                <a class="font-weight-medium d-block" href="#teachers">Abdoulaye<br />SANKHON</a>
                                <span class="text-white">Maintenance</span>
                            </div>
                        </div>                       
                    </carousel>
                </div>
            </section>

            <!-- ====================================
            ---	APEL SECTION
            ===================================== -->
            <section id="assos" style="display:none;">
                <section class="pt-9" id="apel">
                    <div class="container">
                        <div class="section-title justify-content-center mb-2 mb-md-8 wow fadeInUp">
                            <span class="shape shape-left bg-info"></span>
                            <h2 class="text-danger">Association des parents d'éléves<br />de SAINT-LOUIS BLAISE PASCAL (APEL)</h2>
                            <span class="shape shape-right bg-info"></span>
                        </div>
                        <div class="mt-4 row text-left">
                            <div class="col-lg-12">
                                <div class="media-body">
                                    L'Association des Parents d'éléves de l'Enseignement Libre (APEL) est une organisation qui regroupe tous les parents d'éléves de l'enseignement privé.
                                    Votre enfant est à Saint-Louis Blaise Pascal; vous en faites donc partie!
                                    <br />
                                    Nous vous souhaitons la bienvenue.
                                    <br /><br />
                                    L'APEL se compose d'un bureau de 12 membres parents d'éléves, entièrement bénévoles avec :<br /><br />
                                    <ul>
                                        <li>un(e) Président(e)</li>
                                        <li>un(e) Vice-Président(e)</li>
                                        <li>un(e) Trésorier(e)</li>
                                        <li>un(e) Secrétaire</li>
                                        <li>huit autres membres</li>
                                    </ul>
                                    <div class="py-2 text-center">
                                        <img class="img-fluid" src="../assets/img/trombi.jpg" alt="Trombinoscope APEL Saint Louis"/>
                                    </div>   
                                    Le bureau est élu tous les ans, par les parents d'éléves, lors de l'assemblée générale en début d'année scolaire.<br />
                                    Le rôle de l'APEL et de son Bureau est de créer un lien permanent entre les parents d'éléves, l'école et la vie de celle-ci. Ce lien est important
                                    car il est à l'origine des idées et des améliorations que nous pouvons tous apporter pour nos enfants et pour la vie de l'école.
                                    Ce lien s'organise sur les thèmes principaux suivants :<br />
                                    <ul>
                                        <li>Accueil des nouveaux parents et entraide à leur intégration</li>
                                        <ul>
                                            <li>Réunion pédagogique en début d'année</li>
                                            <li>Journée portes ouvertes</li>
                                        </ul>
                                        <li>Accompagnements lors des activités régulières sportives, pédagogiques et religieuses</li>
                                        <ul>
                                            <li>Piscine</li>
                                            <li>Bibliothèque</li>
                                            <li>Célébrations</li>
                                        </ul>
                                        <li>Préparations et animations des différentes manifestations au cours de l'année</li>
                                        <ul>
                                            <li>Fête de Noêl</li>
                                            <li>Fêtes des ateliers et du théétre</li>
                                            <li>Fête de fin d'année</li>
                                        </ul>
                                        <li>Débats participatifs avec les instances de l'école pour l'amélioration du cadre de vie</li>
                                        <ul>
                                            <li>Au conseil d'établissement</li>
                                            <li>Au conseil des éléves</li>
                                        </ul>
                                        <li>Mise en place d'autres activités au sein même de l'école</li>
                                        <ul>                                        
                                            <li>Vente de chocolats</li>
                                        </ul>
                                    </ul>
                                    <br />
                                    Ces activités sont importantes puisqu'elles permettent de nous rencontrer régulièrement, d'échanger et ainsi faire progresser la qualité de vie de nos enfants à l'école.
                                    L'APEL est aussi présente pour que vos idées constructives et vos remarques pertinentes soient des vecteurs d'amélioration.<br />
                                    N'hésitez donc pas à nous contacter:
                                    <ul>
                                        <li>Soit par mail, sur EcoleDirecte (cliquer sur APEL)</li>
                                        <li>Soit par courrier, à déposer dans la boite aux lettres APEL située dans le Hall de l'entrée de l'école.</li>
                                    </ul>
                                    <br />
                                    Vous pouvez aussi consulter le site de l'APEL: <a href="http://www.apel.fr/" style="color: Red;" target="_blank">www.apel.fr</a>
                                    <br class="mb-6"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="pt-9" id="ogec">
                    <div class="container">            
                        <div class="section-title justify-content-center mb-2 mb-md-8 wow fadeInUp">
                            <span class="shape shape-left bg-info"></span>
                            <h2 class="text-danger">O.G.E.C.</h2>
                            <span class="shape shape-right bg-info"></span>
                        </div>
                        <div class="mt-4 row text-left">
                            <div class="col-lg-12">
                                L'O.G.E.C., <i>Organisme de Gestion de l'Ecole Catholique</i>, est une association, assurée par des bénévoles, tous parents d'éléves ou anciens parents d'éléves de l'école Saint Louis Blaise Pascal.
                                <br />Son Conseil d'Administration est responsable de la bonne marche financiére de l'établissement.
                                <br />Au sein de ce Conseil siégent obligatoirement le Président de l'APEL, le Directeur Diocésain de l'Enseignement Catholique et le représentant de l'Union diocésaine des Organismes de Gestion.<br />
                                Le chef d'établissement assiste réguliérement aux réunions.
                                <br /><br />L'O.G.E.C. est le gérant du patrimoine immobilier que constituent les locaux de l'école, dont il doit assurer le bon entretien ou l'amélioration, ainsi que la mise en conformité
                                avec les règlements de sécurité. Il est aussi l'employeur du personnel de service aini que du professeur d'éducation physique (dont le traitement n'est pas assuré par l'Etat). Il est également
                                civilement responsable des accidents matériels ou corporels pouvant survenir dans l'établissement.
                                <br />C'est dire la lourde charge assumée par l'O.G.E.C., dont, au quotidien, la téche principale consiste é maintenir l'équilibre entre les recettes peréues auprés des parents et les dépenses
                                de tous ordres.
                            </div>
                        </div>
                    </div>
                </section>
            </section>

            <!-- ====================================
            ---	GALLERY
            ===================================== -->
            <section class="py-9" id="gallery">
                <div class="container">
                    <div class="section-title justify-content-center mb-4 mb-md-8 wow fadeInUp" id="gallery-title">
                        <span class="shape shape-left bg-info"></span>
                        <h2 class="text-danger">Galerie</h2>
                        <span class="shape shape-right bg-info"></span>
                    </div>

                    <div class="button-group justify-content-center wow fadeInUp">
                        <button class="button" :class="[filterOption==='exterieur' ? 'is-checked' : '']" @click="filter('exterieur')">
                            Extérieur
                        </button>
                        <button class="button" :class="[filterOption==='interieur' ? 'is-checked' : '']" @click="filter('interieur')">
                            Intérieur
                        </button>
                        <button id="btnShowAll" class="button" :class="[filterOption === 'tous' ? 'is-checked' : '']" @click="filter('tous')">Tout voir</button>
                    </div>

                    <div id="SLBP-gallery-grid" style="display:none">

                        <isotope ref="cpt" id="gallery-grid" :item-selector="'element-item'" :list="list" :options='option' @filter="filterOption=arguments[0]" @layout="currentLayout=arguments[0]">

                            <div v-for="(element,index) in list" class="col-md-4 col-lg-3 col-xs-12" :key="index" :class='[element.category]'>

                                <div class="position-relative w-100 media media-hoverable justify-content-center">
                                    <!-- <img class="media-img w-100 m-2" v-bind:src='element.src' alt="galerie-slbp">
                                    <a class="media-img-overlay" data-fancybox="gallery" v-bind:href='element.srcLg'>
                                        <div class="btn btn-squre">
                                            <i class="fa fa-search-plus"></i>
                                        </div>
                                    </a> -->

                                    <div @click="imgClick(element)" style="cursor:pointer;">
                                        <img class="media-img w-100 m-2" v-bind:src='element.src' alt="galerie-slbp">
                                    </div>                                   
                                </div>
                                <Modal v-if='element.show' v-bind:class="{show: element.show}" @close="element.show = false">
                                    <div slot='body'>
                                        <img v-bind:src="element.srcLg" class="img-fluid"/>
                                    </div>        
                                </Modal>
                            </div>

                        </isotope>

                    </div>

                </div>

            </section>


            <!-- ====================================
            ---	NEWS SECTION
            ===================================== -->
            <section class="pt-9 pb-7 mt-2 bg-warning" id="lastNews">
                <div class="container">
                    <div class="section-title justify-content-center mb-4 mb-md-8 wow fadeInUp">
                        <span class="shape shape-left bg-danger"></span>
                        <h2 class="text-white">Dernières Actualités</h2>
                        <span class="shape shape-right bg-danger"></span>
                    </div>

                    <div class="row wow fadeInUp">                        
                        <div class="col-md-12">
                            <h5 class="text-primary font-weight-bold">Météo</h5>
                            <iframe class="mb-8" id="widget_autocomplete_preview" max-width="550" height="155" frameborder="1" src="https://meteofrance.com/widget/prevision/940220##8BC34AD9"></iframe>
                        </div>
                        <div v-if="newsLoading" class="col-md-12 py-4">
                           <img src="../assets/img/spinner.gif" width="100" alt="Loading..."> 
                        </div>
                        <div class="col-md-12 py-4" v-else-if="listNews.length === 0">
                            <div>Il n'y a pas d'information pour le moment</div> 
                        </div>                              
                        <div v-else class="col-md-4" :key="index" v-for="(news, index) in listNews">                                
                            <div class="card mh-500">
                                <div class="position-relative">
                                    <a href="blog-single-left-sidebar.html">
                                        <img class="card-img-top img-fluid" v-bind:src="'https://api.slbp.fr/StaticFiles/' + news.Filename" alt="SLBP News" />
                                    </a>
                                    <div class="card-img-overlay p-0" style="text-align: left;">
                                        <span class="badge badge-rounded badge-success m-1">{{frNewsTime(news.DateParution)}}</span>
                                    </div>
                                </div>

                                <div class="card-body border-top-5 px-3 border-warning">
                                    <h3 class="card-title text-success">
                                        <!-- <a class="text-warning text-capitalize d-block text-truncate" href="blog-single-left-sidebar.html">{{news.Titre}}</a> -->
                                        {{news.Titre}}
                                    </h3>
                                    <ul class="list-unstyled d-flex flex-md-column flex-lg-row">
                                        <li class="mr-2">
                                            <!-- <a class="text-muted" href="blog-single-left-sidebar.html">
                                                
                                            </a> -->
                                            <i class="fa fa-user mr-2" aria-hidden="true"></i>{{news.Auteur}}
                                        </li>
                                    </ul>
                                    <p class="mb-2 text-left">{{news.Contenu}}</p>
                                </div>
                            </div>                            
                        </div>                      
                    </div>
                </div>
            </section>


            <!-- ====================================
            ---	APEL SECTION
            ===================================== -->
            <section class="pt-9 pb-7 mt-3" id="APELNews">
                <div class="container">
                    <div class="section-title justify-content-center mb-4 mb-md-8 wow fadeInUp">
                        <span class="shape shape-left bg-info"></span>
                        <h2 class="text-danger">Dernières Actualités APEL</h2>
                        <span class="shape shape-right bg-info"></span>
                    </div>

                    <div class="row wow fadeInUp">
                        <div v-if="apelNewsLoading" class="col-md-12">
                           <img src="../assets/img/spinner.gif" width="100" alt="Loading..."> 
                        </div>
                        <div v-else-if="listAPEL.length === 0" class="col-md-12">Il n'y a pas d'information APEL pour le moment</div>
                        <div v-else class="col-md-4" :key="index" v-for="(apel, index) in listAPEL">
                            <div class="card mh-500">
                                <div class="position-relative">
                                    <a href="blog-single-left-sidebar.html">
                                        <img v-if="apel.Filename" class="card-img-top img-fluid" v-bind:src="'https://api.slbp.fr/StaticFiles/' + apel.Filename" alt="SLBP News">
                                    </a>
                                    <div class="card-img-overlay p-0" style="text-align: left;">
                                        <span class="badge badge-rounded badge-success m-1">{{frNewsTime(apel.DateParution)}}</span>
                                    </div>
                                </div>

                                <div class="card-body border-top-5 px-3 border-warning">
                                    <h3 class="card-title text-warning">
                                        {{apel.Titre}}
                                    </h3>
                                    <ul class="list-unstyled d-flex flex-md-column flex-lg-row">
                                        <li class="mr-2">
                                            <i class="fa fa-user mr-2" aria-hidden="true"></i>{{apel.Auteur}}
                                        </li>
                                    </ul>
                                    <p class="mb-2 text-left">{{apel.Contenu}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--<div class="btn-aria text-center mt-4 wow fadeInUp">
                        <a href="blog-grid.html" class="btn btn-danger text-uppercase">View More</a>
                    </div>-->
                </div>
            </section>


            <!-- ====================================
            ---	Cantine SECTION
            ===================================== -->
            <section class="pt-9 pb-7 mt-3 bg-success" id="menuCantine" v-bind:style="{ 'background-image': 'url(' + require('../assets/img/background/avator-bg.png') + ')' }">
                <div class="container">
                    <div class="section-title justify-content-center mb-4 mb-md-8 wow fadeInUp">
                        <span class="shape shape-left bg-danger"></span>
                        <h2 class="text-white">Menu de la semaine</h2>
                        <span class="shape shape-right bg-danger"></span>
                    </div>

                    <div v-if="currentMenu.length">
                        <img class="my-1 img-fluid border-1" v-bind:src="'https://api.slbp.fr/StaticFiles/menus/' + this.currentMenu" alt="Menu cantine - Ecole Saint Louis Blaise Pascal, Choisy-le-Roi" />
                    </div>

                    <div v-else>
                        <p>Le menu de la cantine de la semaine n'est pas disponible actuellement.</p>
                    </div>
                </div>
            </section>


            <!-- ====================================
            ---	CALENDRIER SECTION
            ===================================== -->
            <section class="pt-9 pb-7 mt-3" id="calendrier" style="display: none;">
                <div class="container">
                    <div class="section-title justify-content-center mb-4 mb-md-8 wow fadeInUp">
                        <span class="shape shape-left bg-info"></span>
                        <h2 class="text-danger">Calendrier</h2>
                        <span class="shape shape-right bg-info"></span>
                    </div>

                    <h3 class="mt-4">Les événements de l'école</h3>
                    <div class="col-md-12" v-if="listEvents.length == 0">Il n'y a pas d'événement programmé pour le moment</div>
                    <div class="row wow fadeInUp tableau">
                        <ul class="eventsTab" id="eventsTab">
                            <li :key="cpt" v-for="(event, cpt) in listEvents" class="size60">
                                <div class="card m-2 border-warning height50">
                                    <p class="pt-2 vacances">
                                        <strong>{{event.Title}}: </strong>
                                        <span v-if="event.From" class="on" :id="formatDate(event.From)">{{frTime(event.From)}} </span>
                                        <span class="comment">{{event.Comment}}</span>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <h3 class="mt-4">Les rendez-vous de l'APEL</h3>
                    <div class="col-md-12" v-if="listAPELDates.length == 0">Il n'y a pas de rendez-vous APEL programmé pour le moment</div>
                    <div class="row wow fadeInUp tableau">
                        <ul class="APELDatesTab" id="APELDatesTab">
                            <li :key="idx" v-for="(apel, idx) in listAPELDates" class="size60">
                                <div class="card m-2 border-warning height50">
                                    <p class="pt-2 vacances">
                                        <strong>{{apel.Label}}: </strong>
                                        Le <span class="apelDate" :id="formatDate(apel.Date)">{{frTime(apel.Date)}} </span>
                                        <span class="info"> - {{apel.Info}}</span>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <h3 class="mt-4">Les vacances</h3>
                    <div class="row wow fadeInUp tableau">
                        <ul class="holidaysTab" id="holidaysTab">
                            <li :key="index" v-for="(holiday, index) in listHolidays" class="size60">
                                <div class="card m-2 border-warning height50">
                                    <p class="pt-2 vacances">
                                        <strong>{{holiday.Label}}: </strong>
                                        du <span class="from" :id="formatDate(holiday.From)">{{frTime(holiday.From)}}</span> après la classe
                                        au <span class="to" :id="formatDate(holiday.To)">{{frTime(holiday.To)}}</span> au matin
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
            </section>

            <!-- ====================================
            ---	CONTACT SECTION
            ===================================== -->
            <section class="bg-light py-7 py-md-10" id="contactUs">
                <div class="container">
                    <div class="row wow fadeInUp">
                        <div class="col-sm-6 col-xs-12">
                            <div class="section-title align-items-baseline mb-4">
                                <h2 class="text-danger px-0 mb-0">Contact</h2>
                            </div>
                            <p class="text-dark font-size-15 text-left">Pour joindre l'Ecole Saint Louis Blaise Pascal:</p>
                            <ul class="list-unstyled">
                                <li class="media align-items-center mb-3">
                                    <div class="icon-rounded-circle-small bg-warning mr-2">
                                        <i class="fa fa-map-marker text-white"></i>
                                    </div>
                                    <div class="media-body text-left">
                                        <p class="mb-0">6 Rue de Verdun - 94600 Choisy le Roi</p>
                                    </div>
                                </li>
                                <li class="media align-items-center mb-3">
                                    <div class="icon-rounded-circle-small bg-success mr-2">
                                        <i class="fa fa-envelope text-white"></i>
                                    </div>
                                    <div class="media-body text-left">
                                        <p class="mb-0">Directrice: <a class="text-color" href="mailto:benedicte_tassi@slbp.ovh">benedicte_tassi@slbp.ovh</a></p>
                                    </div>
                                </li>
                                <li class="media align-items-center mb-3">
                                    <div class="mr-2" style="width: 28px">
                                    </div>

                                    <div class="media-body text-left">
                                        <p class="mb-0">
                                            Secrétaire: <a class="text-color" href="mailto:secretariat-saint-louis@wanadoo.fr">valerie_turpin@slbp.ovh</a>
                                        </p>
                                    </div>
                                </li>
                                <li class="media align-items-center mb-3">
                                    <div class="mr-2" style="width: 28px">
                                    </div>
                                    <div class="media-body text-left">
                                        <p class="mb-0">
                                            Comptable: <a class="text-color" href="mailto:compta-saint-louis@wanadoo.fr">compta-saint-louis@wanadoo.fr</a>
                                        </p>
                                    </div>
                                </li>
                                <li class="media align-items-center mb-3">
                                    <div class="icon-rounded-circle-small bg-info mr-2">
                                        <i class="fa fa-phone text-white"></i>
                                    </div>
                                    <div class="media-body text-left">
                                        <p class="mb-0">01 48 84 10 95</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <form>
                                <div class="form-group form-group-icon">
                                    <i class="fa fa-user "></i>
                                    <input type="text" id="fullName" v-model="formData.fullName" class="form-control border-warning" placeholder="Nom complet" required>
                                </div>
                                <div class="form-group form-group-icon">
                                    <i class="fa fa-envelope "></i>
                                    <input type="email" id="email" v-model="formData.email" class="form-control border-success" placeholder="Adresse email" required>
                                </div>
                                <div class="form-group form-group-icon">
                                    <i class="fa fa-comments "></i>
                                    <textarea class="form-control border-info" id="message" v-model="formData.message" placeholder="Message" rows="6"></textarea>
                                </div>
                                <!-- <div class="input-group">
                                    <VueRecaptcha ref="recaptcha"
                                                  @verify="onCaptchaVerified"
                                                  @expired="onCaptchaExpired"
                                                  size="invisible"
                                                  sitekey="6LeqCN4ZAAAAAH_O9e2JYxPZ3HoZy1EZmUSHlCeH">
                                    </VueRecaptcha>
                                </div> -->
                                <button type="submit" class="btn btn-danger float-right text-uppercase" @click.prevent="sendEmail">
                                    Envoyer
                                </button>
                                <!-- <div v-cloak class="">
                                    <div v-show="serverError">
                                        {{serverError}}
                                    </div>
                                </div> -->
                                <div class="message" :class="sendingSuccessful ? 'success' : 'error'">
                                    <p>{{this.sendingResult}}</p>
                                </div>
                                <!--<div class="successful-server-response-wrapper" v-cloak>
                                    <div v-show="sucessfulServerResponse" class="successful-server-response">
                                        {{sucessfulServerResponse}}
                                    </div>
                                </div>-->
                            </form>
                        </div>
                    </div>
                    <div class="row wow fadeInUp mt-2">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2629.7172858483405!2d2.406045!3d48.768195!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e674875f2f1b11%3A0x50171d0b66ed06c6!2s6%20Rue%20de%20Verdun%2C%2094600%20Choisy-le-Roi!5e0!3m2!1sen!2sfr!4v1609590144355!5m2!1sen!2sfr" width="1920" height="500" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    </div>
                </div>

            </section>

        </div> <!-- element wrapper ends -->
        
        <!-- ====================================
        ---	FOOTER
        ===================================== -->
        <footer class="footer-bg-img">

            <!--scrolling-->
            <div class="scrolling">
                <a href="#pageTop" class="back-to-top" id="back-to-top" style="opacity: 1;">
                    <i class="fa fa-arrow-up" aria-hidden="true"></i>
                </a>
            </div>
            <div class="copyright center">
                <span>Déclaration CNIL N° 1154813</span>
                <span>   |   </span> 
                <span class="text-success"><a href="https://dla-webagency.com" target="_blank">  Développé par dla-webagency</a></span>
            </div>    
            <!-- Footer Color Bar -->
            <div class="color-bar">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col color-bar bg-warning"></div>
                        <div class="col color-bar bg-danger"></div>
                        <div class="col color-bar bg-success"></div>
                        <div class="col color-bar bg-info"></div>
                        <div class="col color-bar bg-purple"></div>
                        <div class="col color-bar bg-pink"></div>
                        <div class="col color-bar bg-warning d-none d-md-block"></div>
                        <div class="col color-bar bg-danger d-none d-md-block"></div>
                        <div class="col color-bar bg-success d-none d-md-block"></div>
                        <div class="col color-bar bg-info d-none d-md-block"></div>
                        <div class="col color-bar bg-purple d-none d-md-block"></div>
                        <div class="col color-bar bg-pink d-none d-md-block"></div>
                    </div>
                </div>
            </div>
        </footer>

    </div>
</template>

<script>
    
    import carousel from 'vue-owl-carousel'
    import isotope from 'vueisotope'
    import axios from 'axios'
    import lozad from 'lozad'
    import CookieLaw from 'vue-cookie-law'

    //import VueRecaptcha from 'vue-recaptcha';
    import moment from 'moment';
    import Modal from './Modal';

    //let $ = jQuery;
    global.jQuery = require('jquery');
    var $ = global.jQuery;
    window.$ = $;

    export default {
        name: 'Accueil',
        components: { carousel, isotope, Modal, CookieLaw },
        // props: {
        //     items: { type: Array, default:() => [] }
        // },
        data() {
            return {
                messageAlerte: '',
                showComportement: false,
                comportementLg: '',
                newsLoading: false,
                apelNewsLoading: false,
                listNews: [],
                currentMenu: '',
                listAPEL: [],
                listHolidays: [],
                listAPELDates: [],
                listEvents: [],
                formData: {
                    fullName: "",
                    email: "",
                    message: ""
                },
                classes: {
                  ps: '', 
                  ms: '',
                  gs: '',
                  cp: '',
                  ce1: '',
                  ce2: '',
                  cm1: '',
                  cm2: '',
                  ulis: ''
                },
                sendingSuccessful: false,
                sendingResult: '',
                status: '',
                sucessfulServerResponse: '',
                serverError: '',
                //baseUrl: window.location.origin,
                layouts: [
                    "fitRows"
                ],
                list: [
                    {
                        src: require("../assets/img/gallery/m1.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_1.jpg"),
                        category: "exterieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m2.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_2.jpg"),
                        category: "exterieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m3.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_3.jpg"),
                        category: "exterieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m4.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_4.jpg"),
                        category: "exterieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m5.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_5.jpg"),
                        category: "exterieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m6.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_6.jpg"),
                        category: "exterieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m7.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_7.jpg"),
                        category: "exterieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m8.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_8.jpg"),
                        category: "exterieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m9.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_9.jpg"),
                        category: "exterieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m10.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_10.jpg"),
                        category: "exterieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m11.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_11.jpg"),
                        category: "exterieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m12.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_12.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m13.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_13.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m14.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_14.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m15.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_15.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m16.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_16.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m17.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_17.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m18.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_18.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m19.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_19.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m20.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_20.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m21.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_21.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m22.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_22.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m23.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_23.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m24.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_24.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m25.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_25.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m26.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_26.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m27.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_27.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m28.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_28.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m29.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_29.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m30.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_30.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m31.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_31.jpg"),
                        category: "interieur",
                        show: false
                    },
                    {
                        src: require("../assets/img/gallery/m32.jpg"),
                        srcLg: require("../assets/img/gallery/DLA_32.jpg"),
                        category: "interieur",
                        show: false
                    }                                                                                                                                                                                                                                                                                                                                                                                                                
                ],
                currentLayout: 'masonry',
                selected: null,
                sortOption: "original-order",
                filterOption: "",
                option: {
                    itemSelector: ".element-item",
                    getFilterData: {
                        "tous": function () {
                            return true;
                        },
                        exterieur: function (el) {
                            return el.category === "exterieur";
                        },
                        interieur: function (el) {
                            return el.category === "interieur";
                        },
                        bibliotheque: function (el) {
                            return el.category === "bibliotheque";
                        },
                        gymnase: function (el) {
                            return el.category === "gymnase";
                        },
                        cantine: function (el) {
                            return el.category === "cantine";
                        }
                    }
                }
            }
        },
        computed: {
            fullMsgAlerte(){ return this.frTime(this.messageAlerte.DateAlerte) + ":  " + this.messageAlerte.msgAlerte;},
            largeComportement () {
                return {
                ...this.comportementLg, 
                large: this.comportementLg && require(`../assets/img/comportement/lg/${this.comportementLg}`)
                }
            }
        },
        methods: {
            imgClick(item) {
                //console.log(item);
                item.show = true;
                //console.log(item);
            },
            livretClick(imgLg) {
                this.showComportement = true;
                this.comportementLg = imgLg;
            },
            frTime: function(dt) {
                moment.locale("fr");
                return moment(dt).format('ll');
            },
            frNewsTime: function(dt) {
                moment.locale("fr");
                return moment(dt).format('D MMM');
            },
            filter: function (key) {
                $('#SLBP-gallery-grid').show();
                this.$refs.cpt.filter(key);
                //this.$refs.cpt.layout(key);
                this.$refs.cpt.sort(key);
            },
            display: function (div) {
                $("#" + div).show();
            },
            hideContentsMenu: function () {
                $("#calendrier").hide();
                $("#school").hide();
                $("#subscription").hide();
            },
            showContent: function (myDiv) {
                this.hideContentsMenu();
                $(myDiv).show();
                this.checkDates('holidaysTab');
                this.checkDates('APELDatesTab');
                this.checkDates('eventsTab');
            },
            formatDate: function (dt) {
                var today = new Date(dt);
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = today.getFullYear();

                today = dd + '/' + mm + '/' + yyyy;
                return today;
            },
            checkDates: function (liste) {
                $('ul' + '.' + liste).find('li').each(function () {

                    var today = new Date();
                    var dd = String(today.getDate()).padStart(2, '0');
                    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                    var yyyy = today.getFullYear();
                    today = dd + '/' + mm + '/' + yyyy;
                    var correctToday = today.replace(/(\d+)\/(\d+)\/(\d+)/, "$3/$2/$1");
                    today = new Date(correctToday);

                    if ($(this).parent().hasClass("holidaysTab") || $(this).parent().hasClass("eventsTab")) {
                        var from = $(this).find('span.from').attr("id");
                        var correctFrom = from.replace(/(\d+)\/(\d+)\/(\d+)/, "$3/$2/$1");
                        from = new Date(correctFrom);

                        var to = $(this).find('span.to').attr("id");
                        var correctTo = to.replace(/(\d+)\/(\d+)\/(\d+)/, "$3/$2/$1");
                        to = new Date(correctTo);

                        if (today >= from && today <= to) {
                            $(this).find('div.card').css("background-color", "lightgreen");
                        }
                    }
                    if ($(this).parent().hasClass("APELDatesTab")) {
                        var apelDate = $(this).find('span.apelDate').attr("id");
                        var correctApelDate = apelDate.replace(/(\d+)\/(\d+)\/(\d+)/, "$3/$2/$1");
                        apelDate = new Date(correctApelDate);

                        if (today >= apelDate) {
                            $(this).find('div.card').css("background-color", "lightgreen");
                        }
                    }
                });
            },
            sendEmail: function () {
                //this.$refs.recaptcha.execute();

                var datas = {
                   fullName: this.formData.fullName,
                   email: this.formData.email,
                   message: this.formData.message
                };

                let self = this;

                axios
                   .post('https://api.slbp.fr/api/Email/Send',
                       datas,
                       {
                           headers: {
                               'Content-Type': 'application/json'
                           }
                       }
                   ).then(function () {
                       console.log('EMAIL SENDING SUCCESSFUL!!');   // eslint-disable-line no-console
                       self.sendingSuccessful = true;
                       self.sendingResult = "Votre message a bien été envoyé!";

                       self.formData.fullName = '';
                       self.formData.email = '';
                       self.formData.message = '';
                   })
                   .catch(function (err) {
                       console.log('EMAIL SENDING FAILURE!! ' + err);    // eslint-disable-line no-console
                       self.sendingSuccessful = false;
                       self.sendingResult = "Echec lors de l'envoi du message.";
                   });
            },
            // onCaptchaVerified: function (recaptchaToken) {
            //     const self = this;
            //     self.status = "submitting";
            //     self.$refs.recaptcha.reset();

            //     var datas = {
            //         fullName: this.formData.fullName,
            //         email: this.formData.email,
            //         message: this.formData.message,
            //         recaptchaToken: recaptchaToken
            //     };

            //     axios
            //         .post('/api/Email/Send',
            //             datas,
            //             {
            //                 headers: {
            //                     'Content-Type': 'application/json'
            //                 }
            //             }
            //         ).then(function (response) {
            //             console.log('EMAIL SENDING SUCCESSFUL!!');   // eslint-disable-line no-console
            //             self.sucessfulServerResponse = response.data.message;
            //             self.sendingSuccessful = true;
            //             self.sendingResult = "Votre message a bien été envoyé!";

            //             self.formData.fullName = '';
            //             self.formData.email = '';
            //             self.formData.message = '';
            //         })
            //         .catch(function (err) {
            //             console.log('EMAIL SENDING FAILURE!! ' + err);    // eslint-disable-line no-console
            //             self.sendingSuccessful = false;
            //             self.sendingResult = "Echec lors de l'envoi du message. (" + err + ")";
            //             self.serverError = getErrorMessage(err);

            //             //helper to get a displayable message to the user
            //             function getErrorMessage(err) {
            //                 let responseBody;
            //                 responseBody = err.response;
            //                 if (!responseBody) {
            //                     responseBody = err;
            //                 }
            //                 else {
            //                     responseBody = err.response.data || responseBody;
            //                 }
            //                 return responseBody.message || JSON.stringify(responseBody);
            //             }
            //         }).then(() => {
            //             self.status = "";
            //         });
            // },
            // onCaptchaExpired: function () {
            //     this.$refs.recaptcha.reset();
            // }
        },       
        mounted() {
            //lazy loading image
            let elmts = document.querySelectorAll(".lazy-load");
            if (window.img_observer && window.img_observer.observer) {
                window.img_observer.observer.disconnect();
                delete window.img_observer;
                for (let i = 0, len = elmts.length; i < len; i++) {
                    // load image only when the image src is updated
                    const img = elmts[i].getAttribute('data-src') || false;
                    if (img &&
                        elmts[i].getAttribute('data-loaded') === 'true' &&
                        img !== elmts[i].getAttribute('src')) {
                        elmts[i].setAttribute('data-loaded', false);
                    }
                }
            }
            window.img_observer = lozad(elmts);
            window.img_observer.observe();

            //Get Alert if exists
            axios.get('https://api.slbp.fr/api/Actualite/GetAlerte').then(response => {
                this.messageAlerte = response.data;
            }).catch(() => {});

            //Get list of news
            this.newsLoading = true;
            axios.get('https://api.slbp.fr/api/Actualite/GetAllNews').then(response => {
                this.listNews = response.data;
                this.newsLoading = false;
            });
            
           //Get Menu
            axios.get('https://api.slbp.fr/api/Menu/GetCurrentMenu').then(response => {
                this.currentMenu = response.data;
            });
            
           //Get list of APEL informations
           this.apelNewsLoading = true;
            axios.get('https://api.slbp.fr/api/APELActualite/GetAllNews').then(response => {
                this.listAPEL = response.data;
                this.apelNewsLoading = false;
            });

           //Get list of Holidays
           axios.get('https://api.slbp.fr/api/Holiday/GetAllHolidays').then(response => {
               this.listHolidays = response.data;
           });

           //Get list of Events
           axios.get('https://api.slbp.fr/api/Event/GetAllEvents').then(response => {
               this.listEvents = response.data;
           });

           //Get list of APEL dates
           axios.get('https://api.slbp.fr/api/APELDate/GetAllAPELDates').then(response => {
               this.listAPELDates = response.data;
           });

           //Get supplies file names
           let self = this;
           axios.get('https://api.slbp.fr/api/Supplies/getAllSuppliesFiles').then(response => {
                var files = response.data;

                files.forEach(function(item) {
                    switch(item) {
                        case 'PS.pdf':
                            self.classes.ps = 'https://api.slbp.fr/StaticFiles/Fournitures/' + item;
                            break;
                        case 'MS.pdf':
                            self.classes.ms = 'https://api.slbp.fr/StaticFiles/Fournitures/' + item;
                            break;
                        case 'GS.pdf':
                            self.classes.gs = 'https://api.slbp.fr/StaticFiles/Fournitures/' + item;
                            break;
                        case 'CP.pdf':
                            self.classes.cp = 'https://api.slbp.fr/StaticFiles/Fournitures/' + item;
                            break;
                        case 'CE1.pdf':
                            self.classes.ce1 = 'https://api.slbp.fr/StaticFiles/Fournitures/' + item;
                            break;
                        case 'CE2.pdf':
                            self.classes.ce2 = 'https://api.slbp.fr/StaticFiles/Fournitures/' + item;
                            break;
                        case 'CM1.pdf':
                            self.classes.cm1 = 'https://api.slbp.fr/StaticFiles/Fournitures/' + item;
                            break;
                        case 'CM2.pdf':
                            self.classes.cm2 = 'https://api.slbp.fr/StaticFiles/Fournitures/' + item;
                            break;
                        case 'ULIS.pdf':
                            self.classes.ulis = 'https://api.slbp.fr/StaticFiles/Fournitures/' + item;
                            break;                                                                                                                                                                                                                                                                                   
                    }
                });


           });
        }
    }
</script>


<style scoped>
    .banner {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .banner span {
        font-family: inherit;
        color: white;
    }

    .size60 {
        width: 60%;
        margin: auto;
    }

    .tableau {
        width: 100%;
        text-align: center;
    }

    #holidaysTab, #APELDatesTab, #eventsTab {
        margin: auto;
        width: 100%;
        list-style-type: none;
    }

    .height50 {
        min-height: 50px;
    }

    .noPuce {
        list-style-type: none;
    }

    .matin {
        background-color: #f7f7f7;
        max-width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        /* Add box-shadow */
        box-shadow: 2px 2px 3px #aaaaaa;
        /* Rounded corners */
        -moz-border-radius: 15px;
        border-radius: 15px;
    }

    .journee {
        background-color: #f7f7f7;
        max-width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        /* Add box-shadow */
        box-shadow: 2px 2px 3px #aaaaaa;
        /* Rounded corners */
        -moz-border-radius: 15px;
        border-radius: 15px;
    }

    .soir {
        background-color: #f7f7f7;
        max-width: 100%;
        padding-top: 10px;
        padding-bottom: 40px;
        /* Add box-shadow */
        box-shadow: 2px 2px 3px #aaaaaa;
        /* Rounded corners */
        -moz-border-radius: 15px;
        border-radius: 15px;
    }

    .horaire {
        display: flex;
    }

    .heure {
        width: 25%;
        text-align: left;
        margin-left: 15px;
        padding-top: 5px;
    }

    .heureDetail {
        max-width: 75%;
        text-align: left;
    }

.supplies {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#comportement {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 15px 5px;
}

#comportement img {
    max-width: 250px;
}

.largeComportement {
    max-width: 400px;
    margin-left: 10px;
}

    .alert {
        position: absolute;
        top: 300px;
        z-index: 10;
        background-color: white;
        color: red;
        width: 100%;        
        font-weight: 700;
    }

/* Texte défilant */
.messagedefilant {
  display: block;  
  padding: 0;
  overflow: hidden;
  margin: 0;
  max-width: 100%;
  height: 32px;
}
 
.messagedefilant div {
  position: absolute;
  min-width: 100%; /* au minimum la largeur du conteneur */
}
 
.messagedefilant div span, 
.messagedefilant div:after {
  /* position: relative; */
  display: inline-block;
  font-size: 1.2rem;
  white-space: nowrap;
  top:0;
}
 
.messagedefilant div span {
  animation: defilement 8s infinite linear;
}
 
.messagedefilant div:after {
  position: absolute;
  top:0; left:0;
  content:attr(data-text);
  animation: defilement2 8s infinite linear;
}
 
@keyframes defilement {
  0% { margin-left: 0; }
  100% { margin-left: -100%; }
}
 
@keyframes defilement2 {
  0% { margin-left: 100%; }
  100% { margin-left: 0%; }
}  

    @media (max-width: 575px){
        .alert {
            top: 210px;
        }  
        /* Texte défilant */
        .messagedefilant {
        display: block;  
        padding: 0;
        overflow: hidden;
        margin: 0;
        max-width: 175%;
        height: 32px;
        }
        
        .messagedefilant div {
        position: absolute;
        min-width: 100%; /* au minimum la largeur du conteneur */
        margin-top: 4px;
        }
        
        .messagedefilant div span, 
        .messagedefilant div:after {
        /* position: relative; */
        display: inline-block;
        font-size: 0.9rem;
        white-space: nowrap;
        top:2;
        }
        
        .messagedefilant div span {
        animation: defilement 8s infinite linear;
        }
        
        .messagedefilant div:after {
        position: absolute;
        top:0; left:0;
        content:attr(data-text);
        animation: defilement2 8s infinite linear;
        }
        
        @keyframes defilement {
        0% { margin-left: 0; }
        100% { margin-left: -100%; }
        }
        
        @keyframes defilement2 {
        0% { margin-left: 100%; }
        100% { margin-left: 0%; }
        }    
    }
</style>
